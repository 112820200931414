import React, { useState } from "react";
import "./View.css"; // Add custom styles

const apiIp = process.env.REACT_APP_API_IP;

const View = () => {
  const [bongId, setBongId] = useState(""); // State for bongId input
  const [data, setData] = useState([]); // State for fetched data
  const [hasQueried, setHasQueried] = useState(false); // Add a flag to track if a query has been made

  // Fetch data by bongId
  const loadData = () => {
    if (!bongId) {
      alert("Bitte Bongnummer eintragen.");
      return;
    }

    fetch(`${apiIp}/api/v1/address/${bongId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setHasQueried(true); // Set flag to true after querying
        setData(data); // Set fetched data
      })
      .catch((error) => console.error("Error fetching form data:", error));
  };

  return (
    <div className="query-container">
      <h2>Addresssuche per Bongnummer</h2>

      {/* Input for Bong ID */}
      <div className="input-group">
        <label htmlFor="bongId">Bongnummer:</label>
        <input
            type="text"
            id="bongId"
            value={bongId}
            onChange={(e) => setBongId(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                loadData(); // Call loadData when pressing Enter
                }
            }}
          placeholder="Bongnummer hier Eingeben"
        />
        <button onClick={loadData}>Suchen</button>
      </div>

      {/* Display fetched data */}
      {hasQueried && <div className="results">
        {data.length > 0 ? (
          data.map((form) => (
            <div key={form.id} className="form-card">
              <p><strong>Name:</strong> {form.name}</p>
              <p><strong>Email:</strong> {form.email}</p>
              <p><strong>Street:</strong> {form.streetNumber} {form.street}</p>
              <p><strong>City:</strong> {form.city}, {form.state}</p>
              <p><strong>ZIP Code:</strong> {form.zipCode}</p>
              <p><strong>Country:</strong> {form.country}</p>
            </div>
          ))
        ) : (
          <p>Keine Daten vorhanden</p>
        )}
      </div>}
    </div>
  );
};

export default View;
