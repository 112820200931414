import React, { useState } from "react";
import QRCode from "react-qr-code";
import "./Qr.css"; // Reuse the same CSS file as View

const apiIp = process.env.REACT_APP_API_IP;

const Qr = () => {
  const [bongId, setBongId] = useState(""); // State for bongId input
  const [generatedUrl, setGeneratedUrl] = useState(""); // State for generated QR code URL

  // Generate QR Code
  const generateQrCode = () => {
    if (!bongId) {
      alert("Bitte Bongnummer eintragen.");
      return;
    }

    // Generate URL for QR code
    const url = `${apiIp}/submit?bongId=${bongId}`;
    setGeneratedUrl(url);
  };

  return (
    <div className="query-container">
      <h2>QR Code Generator</h2>

      {/* Input for Bong ID */}
      <div className="input-group">
        <label htmlFor="bongId">Bongnummer:</label>
        <input
          type="text"
          id="bongId"
          value={bongId}
          onChange={(e) => setBongId(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              generateQrCode(); // Call generateQrCode when pressing Enter
            }
          }}
          placeholder="Bongnummer hier Eingeben"
        />
        <button onClick={generateQrCode}>Generieren</button>
      </div>

      {/* Display generated QR Code */}
      {generatedUrl && (
        <div className="results">
            <div className="qr-container">
            <QRCode value={generatedUrl} size={256} />
            </div>
            <div className="url-container">
            <a href={generatedUrl} target="_blank" rel="noopener noreferrer">
                {generatedUrl}
            </a>
            </div>
        </div>
        )}
    </div>
  );
};

export default Qr;
