import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Submit from './pages/Submit';
import View from './pages/View';
import Qr from './pages/Qr';
import './App.css';

function App() {
  return (
    <Router>
        <Routes>
            <Route path="/submit" element={<Submit/>}/>
            <Route path="/view" element={<View/>}/>
            <Route path="/qr" element={<Qr/>}/>
        </Routes>
    </Router>
  );
}

export default App;
