import React, { useState } from "react";
import "./Submit.css"; // Import the CSS file

const apiIp = process.env.REACT_APP_API_IP;

const Submit = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const bongId = urlParams.get("bongId"); // Extract bongId from the URL
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] = useState(false); // New confirmation popup

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    streetNumber: "",
    street: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsConfirmationPopupVisible(true); // Show confirmation popup
  };

  const handleConfirmSubmit = () => {
    const mergedName = `${formData.firstName} ${formData.lastName}`;
    const submissionData = { ...formData, name: mergedName, bongId };

    fetch(`${apiIp}/api/v1/address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submissionData),
    })
      .then((response) => {
        if (response.ok) {
          setIsPopupVisible(true); // Show the success popup
          setIsConfirmationPopupVisible(false); // Hide the confirmation popup
        } else {
          alert("Failed to submit address. Please try again.");
        }
      })
      .catch((error) => console.error("Error submitting address:", error));
  };

  return (
    <div className="form-container">
      {isConfirmationPopupVisible && (
        <div className="popup-container">
          <div className="popup">
            <h3>Confirm Submission</h3>
            <p>Here is what you entered. <br /> Do you want to proceed?</p>

            <div className="submitted-data">
              <table className="submitted-table">
                <tbody>
                  <tr>
                    <td><strong>Name:</strong></td>
                    <td>{formData.firstName} {formData.lastName}</td>
                  </tr>
                  <tr>
                    <td><strong>Email:</strong></td>
                    <td>{formData.email}</td>
                  </tr>
                  <tr>
                    <td><strong>Street Nr.:</strong></td>
                    <td>{formData.streetNumber}</td>
                  </tr>
                  <tr>
                    <td><strong>Street:</strong></td>
                    <td>{formData.street}</td>
                  </tr>
                  <tr>
                    <td><strong>ZIP Code:</strong></td>
                    <td>{formData.zipCode}</td>
                  </tr>
                  <tr>
                    <td><strong>City:</strong></td>
                    <td>{formData.city}</td>
                  </tr>
                  <tr>
                    <td><strong>State:</strong></td>
                    <td>{formData.state}</td>
                  </tr>
                  <tr>
                    <td><strong>Country:</strong></td>
                    <td>{formData.country}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="popup-buttons">
              <button
                className="submit-btn"
                onClick={() => setIsConfirmationPopupVisible(false)}
              >
                Edit
              </button>
              <button className="submit-btn" onClick={handleConfirmSubmit}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {isPopupVisible && (
        <div className="popup-container">
          <div className="popup">
            
            <h3>
            <span className="popup-icon" role="img" aria-label="hook">✔️</span> <br />
            Email Confirmation Send To <br/>
            {formData.email} </h3>
            <p>You can close this page now.</p>

            <div className="submitted-data">
              <h4>Submitted Information:</h4>
              <table className="submitted-table">
                <tbody>
                  <tr>
                    <td><strong>Name:</strong></td>
                    <td>{formData.firstName} {formData.lastName}</td>
                  </tr>
                  <tr>
                    <td><strong>Email:</strong></td>
                    <td>{formData.email}</td>
                  </tr>
                  <tr>
                    <td><strong>Street Nr.:</strong></td>
                    <td>{formData.streetNumber}</td>
                  </tr>
                  <tr>
                    <td><strong>Street:</strong></td>
                    <td>{formData.street}</td>
                  </tr>
                  <tr>
                    <td><strong>ZIP Code:</strong></td>
                    <td>{formData.zipCode}</td>
                  </tr>
                  <tr>
                    <td><strong>City:</strong></td>
                    <td>{formData.city}</td>
                  </tr>
                  <tr>
                    <td><strong>State:</strong></td>
                    <td>{formData.state}</td>
                  </tr>
                  <tr>
                    <td><strong>Country:</strong></td>
                    <td>{formData.country}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <button
              className="submit-btn"
              onClick={() => (window.location.href = "https://drubba-titisee.com/")}
            >
              Leave Page
            </button>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="submit-form">
        <h2>Submit Address</h2>
        <div className="form-group half-width">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="streetNumber">Street Number</label>
          <input
            type="number"
            id="streetNumber"
            name="streetNumber"
            value={formData.streetNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="street">Street</label>
          <input
            type="text"
            id="street"
            name="street"
            value={formData.street}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="zipCode">ZIP Code</label>
          <input
            type="number"
            id="zipCode"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    
    </div>
  );
};

export default Submit;
